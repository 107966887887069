import { Component,Directive, OnInit,Input, ElementRef, ViewChild,AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
//import * as $ from 'jquery';
//import 'magnific-popup';
//import {ImageModal} from 'node_modules/angular2-image-popup/directives/angular2-image-popup/image-modal-popup';

@Component({
  selector: 'app-sitephoto',
  templateUrl: './sitephoto.component.html',
  styleUrls: ['./sitephoto.component.css']
})
  

  
export class SitephotoComponent implements OnInit {
  //@ViewChild('img') imgElement: ElementRef;
  formData = new FormData();
  photoList:any;
  isUpload=false;
  extensionLists: any = {};
  @Input() id: number;
  
  openModalWindow:boolean=false;
  imagePointer:number;
  images =[]
isValidFileType(fName, fType) {
  return this.extensionLists[fType].indexOf(fName.split('.').pop()) > -1;
}
  constructor(private dataService: DataService, private toastr: ToastrService,private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
   
    this.extensionLists.image = ['jpg', 'gif', 'bmp', 'png', 'jpeg',"PNG","JPEG","JPG"];

    this.bindSitePhoto();
  }

  OpenImageModel(imageSrc,images) {
    //alert('OpenImages');
    var imageModalPointer;
    for (var i = 0; i < images.length; i++) {
           if (imageSrc === images[i].img) {
             imageModalPointer = i;
             console.log('jhhl',i);
             break;
           }
      }
    this.openModalWindow = true;
    this.images = images;
    this.imagePointer  = imageModalPointer;
  }
  cancelImageModel() {
    this.openModalWindow = false;
  }
  // ngAfterViewInit(): void {
  //   console.log(this.imgElement)
  //   $(this.imgElement.nativeElement).magnificPopup({ type: 'image' });
  // }

bindSitePhoto(){
  this.dataService.getSitePhoto({ ProjectID: this.id })
  .subscribe(
    result => {
      this.images =[];
      this.photoList=result.data;
      result.data.forEach(element => {

      this.images.push({ thumb: element.SitePhotoVirtualUrl, img: element.SitePhotoVirtualUrl, description: element.Project_Image,Id:element.Id })
      });
      // [
      //   { thumb: 'assets/images/gallery/thumbs/chair.jpg', img: 'assets/images/gallery/chair.jpg', description: 'Image 1' },
      //   { thumb: 'assets/images/gallery/thumbs/chair2.jpg', img: 'assets/images/gallery/chair2.jpg', description: 'Image 2' },
      //   { thumb: 'assets/images/gallery/thumbs/chair3.jpg', img: 'assets/images/gallery/chair3.jpg', description: 'Image 3' },
      //   { thumb: 'assets/images/gallery/thumbs/chair4.jpg', img: 'assets/images/gallery/chair4.jpg', description: 'Image 4' },
        
      // ];
    })
}
  upload(){
    if( !this.isUpload){
      this.toastr.error('',"Please choose a photo");
      return;
    }
    if(localStorage.getItem("projectId")!=""){
      this.id= parseInt(localStorage.getItem("projectId"))
    }
    this.formData.append("Id",  "0");
    this.formData.append("ProjectID",  this.id.toString());
    this.formData.append("UpdateBy", JSON.parse(localStorage.getItem('currentUser')).data.Id);
    this.dataService.saveSitePhoto(this.formData)
    .subscribe(
      result => {
        this.formData = new FormData
        this.toastr.success('', result.message);
        (document.getElementById("imageUpload") as HTMLInputElement).value = "";
        this.isUpload=false;
        this.bindSitePhoto();
      }) 
  }
  readUrl(event: any) {
    this.formData = new FormData
   

    if (this.isValidFileType(event.target.files[0].name, "image") == false) {
      event.srcElement.value = null;
      this.toastr.error('', "Please select only image.");
      return
    }
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        //this.isImage = true;
      //  this.url = event.target.result;
      }
      let files = event.target.files;
      this.formData.append("Project_Image", files[0]);
      reader.readAsDataURL(event.target.files[0]);
      this.isUpload=true;
    }
  }

  delete(item:any){
    let self = this;
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to delete this photo?')
    .then(function(confirmed){
      if(confirmed){
      item.UpdateBy=JSON.parse(localStorage.getItem('currentUser')).data.Id
      self.dataService.deletePhoto(item)
    .subscribe(
      result => {
        self.formData = new FormData
        self.toastr.success('', result.message);
        self.bindSitePhoto();
      }) 
    }
  });
    
  }
}
